import { createMuiTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
// import cyan from '@material-ui/core/colors/green';
import 'fontsource-montserrat';
import 'fontsource-nunito';

const fontFamily = 'nunito';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9120C1',
      // dark: '#9120C1'
    },

    // secondary: {
    //   main: green[500],
    // },
  },
  typography: {
    fontFamily,
  },
});

export default theme;