import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '../components/molecules/TextField';
import { reduxForm, Form, Field } from 'redux-form';
import Submit from '../components/molecules/Submit';
import PropTypes from 'prop-types';


const RegisterForm = (props) => {

  const { pristine, submitting, handleSubmit,session, onSubmit} = props;

  return (
    <Grid item lg={4} style={{margin: '0 auto'}}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="username"
          component={TextField}
          label="Username"
          //   label={I18n.t('forms.story.spotName.label')}
          required
        />
        <Field
          name="fullname"
          component={TextField}
          label="Fullname"
          //   label={I18n.t('forms.story.spotName.label')}
          required
        />
        <Field
          label="Email"
          name="email"
          component={TextField}
          //   label={I18n.t('forms.story.spotName.label')}
          required
        />
        <Field
          name="password"
          component={TextField}
          label="Password"
          //   label={I18n.t('forms.story.spotName.label')}
          required
        />
        <Submit
          pristine={pristine && !session}
          submitting={submitting}
        >
          S'inscrire
        </Submit>
      </Form>
    </Grid>
  );
};

RegisterForm.propTypes = {
  pristine: PropTypes.any,
  submitting: PropTypes.any,
  handleSubmit: PropTypes.any,
  session: PropTypes.any,
  onSubmit: PropTypes.any,
};

export default reduxForm({
  form: 'RegisterForm',
})(RegisterForm);


// default withStyles(styles)(RegisterForm);
