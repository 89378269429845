import { apiRoutes } from '../helpers/apiRoutes';
import Api from '../helpers/Api';

const registerApi = {
  register(session) {
    let route = apiRoutes.users.register;
    return Api.post(route, session);
  },
};

export default registerApi;
