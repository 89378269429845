/* eslint-disable */
import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import { Typography, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import Modal from '../../components/molecules/Modal';
// import PropTypes from 'prop-types';
// import {getAllUsers} from '../../actions/user_actions';
import Image2 from '../../assets/images/svg/document.svg';
import Image3 from '../../assets/images/svg/influencer.svg';
import Image4 from '../../assets/images/third-eye.svg';
import Image5 from '../../assets/images/credit-card.svg';
import Logo from '../../assets/images/andromed.png';
import './index.scss';
import Particles from 'react-particles-js';


const titlesArray = [
  {id: 1, title: 'Veille', icon: Image2 },
  {id: 2, title: 'Prospects', icon: Image3 },
  {id: 3, title: 'Iris', icon: Image4 },
  {id: 4, title: 'Factures', icon: Image2 },
  {id: 5, title: 'Epigrafí (Trello) ', icon: Image5 },
  {id: 6, title: 'Statistiques', icon: Image2 },
  {id: 7, title: 'Calendrier', icon: Image3 },
  {id: 8, title: 'Plans', icon: Image2 },
  {id: 9, title: 'Cloud', icon: Logo },
  {id: 10, title: 'Prévisionnel', icon: Image3 },
  // {id: 11, title: 'Test', icon: Image3 },
  // {id: 12, title: 'Totem', icon: Image2 },
];

const urlBackground = [
  'https://i.pinimg.com/originals/5d/a8/e3/5da8e3b5a0ddfd9aff9277d494bef010.jpg',
  'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?ixlib=rb-1.2.1&w=1000&q=80',
  'https://image.winudf.com/v2/image/Y29tLmxheWxhbGkuZ2FsYXh5d2FsbHBhcGVyX3NjcmVlbl8wXzE1MTEyMjA1ODdfMDEx/screen-0.jpg?fakeurl=1&type=.jpg',
  'https://i.pinimg.com/originals/2e/e3/1f/2ee31f760ca0307272c19e20370fc262.png',
  'https://lh3.googleusercontent.com/proxy/Q-0RHXbkMbScF2wcSIXf7XGn2FqGsZLy7B4ntwzGfwa-PuPAXmdUS5WwTz8L628MTeeGxAppErRrOGgueQ1R326TOVNYV7jCpAsj8NitixKD1qyF1Ndr-kGBhVjyz-RZadfA7hQW_3mPhcat5GANRctziqWAYJCHQw4',
  'https://i.pinimg.com/originals/f6/17/5b/f6175b4288eb6ec5e334a0b403d900bf.png',
  'https://c4.wallpaperflare.com/wallpaper/959/1011/731/digital-art-colorful-wallpaper-preview.jpg',
  'https://www.pixelstalk.net/wp-content/uploads/2016/10/Colorful-Galaxy-Wallpaper-HD-For-Desktop.jpg',
  'https://image.freepik.com/photos-gratuite/ciel-espace-3d-galaxie-etoile-brillante_1048-8908.jpg',
  'https://www.muralswallpaper.com/app/uploads/purple-orange-galaxy-space-plain-1.jpg',
  'https://images.unsplash.com/photo-1538370965046-79c0d6907d47?ixlib=rb-1.2.1&w=1000&q=80',
  'https://images.unsplash.com/photo-1509515837298-2c67a3933321?ixlib=rb-1.2.1&w=1000&q=80'
];

const useStyles = makeStyles(() => ({
  root: {
    position:'relative',
    maxWidth: 200,
    padding: 10,
    marginBottom: 10,
    border: 'none',
    // background: '#40404A',
    // background: 'url(https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?ixlib=rb-1.2.1&w=1000&q=80)',
    backgroundSize: 'cover',
    // border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    boxShadow: '0 0 42px',
  },
  text: {
    color: 'white',
  },
  cardContent: {
    position:'relative',
    zIndex: '1',
    textAlign: 'center'
  },
  icon: {
    color: 'white',
  }
}));
  
const Home = () => {

  const [open, setOpen] = useState(false);

  // setTimeout(() => {
  //   setOpen(true);
  // }, 2000)

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };


  // ==> Comment function to getAllUsers when /home launch or getMe in future
  // useEffect(() => {getAllUsers();}, [getAllUsers]);
  

  const classes = useStyles();

  return (
    <div>
                <Particles
            params={{
	    'particles': {
	        'number': {
	            'value': 500,
	            'density': {
	                'enable': true,
	                'value_area': 1500
	            }
	        },
	        'line_linked': {
	            'enable': true,
	            'opacity': 0.02
	        },
	        'move': {
	            'direction': 'right',
	            'speed': 3
	        },
	        'size': {
	            'value': 1
	        },
	        'opacity': {
	            'anim': {
	                'enable': true,
	                'speed': 1,
	                'opacity_min': 0.05
	            }
	        }
	    },
	    'interactivity': {
	        'events': {
	            'onclick': {
	                'enable': true,
	                'mode': 'push'
	            }
	        },
	        'modes': {
	            'push': {
	                'particles_nb': 1
	            }
	        }
	    },
	    'retina_detect': true
            }}/>
      <Grid container item lg={12} justify="center">
        <Grid container item xs={6} sm={8} md={10} lg={10} justify="space-around">
          {titlesArray.map((title, i) =>
            <Card key={i} style={{marginBottom: 40, background: `url(${urlBackground[i]})`, backgroundSize: 'cover'}} className={classes.root + ' root'} variant="outlined" onClick={title.id !== 3 && handleClickOpen}>
              <CardContent className={classes.cardContent}>
                <Typography variant='h6' align="center" className={classes.text}>
                  {title.title}
                </Typography>
                <img alt={'#'} width={'50%'} height={'auto'} style={{marginTop: 10}} src={title.icon}></img>
                {/* <AssignmentIcon className={classes.icon} fontSize="large"/> */}
              </CardContent>
            </Card> 
          )}

        </Grid>
      </Grid>
      {open ? ReactDOM.createPortal(<Modal open={open} onClose={handleClose}/>, document.body) : null}
    </div>
  );
};

export default connect(undefined)(Home);