import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '../components/molecules/TextField';
import { reduxForm, Form, Field } from 'redux-form';
import Submit from '../components/molecules/Submit';
import PropTypes from 'prop-types';



const LoginForm = (props) => {
  
  const { submitting, handleSubmit, onSubmit} = props;

  return (
    <Grid item lg={12} style={{margin: '0 auto'}}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="email"
          label="Email"
          component={TextField}
          required
        />
        <Field
          name="password"
          type="password"
          component={TextField}
          label="Password"
          required
        />
        <Submit
          submitting={submitting}
        >
          Login
        </Submit>
      </Form>
    </Grid>
  );
};

LoginForm.propTypes = {
  submitting: PropTypes.any,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.any,
};

export default reduxForm({
  form: 'LoginForm',
})(LoginForm);