import React, { useEffect, useState } from 'react';
import PersistentDrawerLeft from '../../components/molecules/Drawer/Drawer';
// import { Typography, Box, Checkbox } from '@material-ui/core';
import './index.css';
import { connect } from 'react-redux';
import { getProject } from '../../actions/project_actions';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
// import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
// import { Doughnut } from 'react-chartjs-2';
// import Switch from '@material-ui/core/Switch';
// import Divider from '@material-ui/core/Divider';
// import { shadows } from '@material-ui/system';
// import OrangeArrow from '../../assets/images/orangeArrow.png';
// import GreenArrow from '../../assets/images/greenArrow.png';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     maxWidth: 120,
//     padding: 20,
//     height: '100%',
//     background: '#23CFD5',
//   },
//   switchBase: {
//     // padding: 1,
//     '&$checked': {
//       transform: 'translateX(16px)',
//       color: theme.palette.common.white,
//       '& + $track': {
//         backgroundColor: '#52d869',
//         opacity: 1,
//         border: 'none',
//       },
//     },
//     '&$focusVisible $thumb': {
//       color: '#52d869',
//       border: '6px solid #fff',
//     },
//   },
//   thumb: {
//     width: 24,
//     height: 24,
//   },
//   track: {
//     // borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(['background-color', 'border']),
//   },
//   checked: {},
//   focusVisible: {},
//   switch: {
//     color: theme.palette.success.main,
//   },
//   button: {
//     background: theme.palette.primary.dark,
//     color: 'white',
//   },
//   colorPurple: {
//     color: theme.palette.primary.dark,
//   },
//   backgroundPurple: {
//     color: 'white',
//     background: theme.palette.primary.dark,
//   },
//   text: {
//     color: 'white',
//   },
//   icon: {
//     color: 'white',
//   },
//   card: {
//     borderRadius: 2,
//     boxShadow: '0 1px 2px rgba(0,0,0,.3), 0 0 0 1px rgba(63,63,68,.1)',
//     background: '#fff',
//     marginLeft: 'auto',
//     marginRight: 'auto',
//     zIndex: 100,
//     // display: 'flex',
//     // flexDirection: 'column',
//     // alignItems: 'center',
//     overflowY: 'auto',
//     position: 'relative',
//     maxWidth: 250,
//     height: 'calc(100vh - 10vh)',
//   },
// }));


const DashboardProject = ({ getProject, match }) => {

  const [checkTags, setCheckTags] = useState([]);
  // const classes = useStyles();
  const [NodeShowProperties, setNodeShowProperties] = useState();
  // const [onSwitch, setOnSwitch] = useState(false);
  const [counter, setCounter] = useState(0);
  const [removeParent, setRemoveParent] = useState(0);


  const settingTrue = () => {
    if (NodeShowProperties)
    {
      setCounter((prevState) => prevState + 1);
      setRemoveParent(0);
    }
  };

  // const removeTheParent = () => {
  //   setRemoveParent((prevState) => prevState + 1);
  // };

  useEffect(() => {
    if (match && match.params) {
      getProject(match && match.params.id);
    }
  }, [getProject, match]);

  // const showGoodChildren = () => {
  //   let childrenArray = [];
  //   let children = project.links.filter((link) => link.from === NodeShowProperties.id);
  //   children.map((child) => childrenArray.push(child));
  //   let to = childrenArray.map((childArrayContent) => childArrayContent.to);
  //   if (to.length === 0) {
  //     to = null;
  //   }
  //   let propertyTo = project.properties.map(
  //     (property) => property.node_id.includes(to) && property
  //   );
  //   let filterProperty = propertyTo.filter((p) => p !== false);
  //   return filterProperty;
  // };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', height: '100vh' }}>
        <div style={{width: '100%'}}>
          <PersistentDrawerLeft
            // handleNodesInformation={handleNodesInformation}
            checkTags={checkTags}
            setRemoveParent={setRemoveParent}
            removeParent={removeParent}
            setCounter={setCounter}
            counter={counter}
            settingTrue={settingTrue}
            setCheckTags={setCheckTags}
            NodeShowProperties={NodeShowProperties}
            setNodeShowProperties={setNodeShowProperties}
            style={{ zIndex: 3 }}
          ></PersistentDrawerLeft>
          {/* <div className="bg"></div> */}
        </div>      
      </div>
    </React.Fragment>
  );
};

DashboardProject.propTypes = {
  getProject: PropTypes.func,
  project: PropTypes.object,
};

const mapStateToProps = () => (state) => ({
  project: state.projects.project,
});

export default withRouter(
  connect(mapStateToProps, { getProject })(DashboardProject)
);
