import React from 'react';
import Image2 from '../../../assets/images/andromed.png';

const Header = () => {
  return (
    <div style={{height: 200, marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>
      <img width={200} alt={'#'} src={Image2}/>
    </div>
  );
};

export default Header;