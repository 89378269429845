import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';

let array = ['Mes Posts', 'Mentions', 'Messages'];
let arrayNumberMessage = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    background: 'rgb(20, 48, 89)',
    color:'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  cardHeader: {
    borderBottom: '1px solid rgb(228 222 222)',
    // border: '1px solid grey'
  }, 
  cardContent: {
    padding: 7,
  },
  drawerClose: {
    background: 'rgb(20, 48, 89)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const FluxPage = () => {   
    
  const classes = useStyles();

    
  return (
    <Grid container item justify="center">
      <Grid container item lg={12}>
        <Grid container item>
          { array.map((v, i) =>
            <Grid key={i} style={{height: '80vh'}} item lg={4}>
              <Card style={{border: 'none', borderRadius: 10, marginRight: 10}}>
                <CardHeader avatar={<FlagIcon/>} className={classes.cardHeader} align="center" title={v}>
                </CardHeader>
                <CardContent className={classes.cardContent} style={{background: v === 'Messages' && 'rgb(239 237 235)', height: '80vh', overflow: 'auto'}}>
                  {v === 'Messages' && arrayNumberMessage.map((v, i) => (
                    <Card key={i} style={{marginBottom: 10}}>
                      <CardContent className={classes.cardContent}>
                        <div>
                          <div style={{height: 30, display: 'inline-block', borderRadius: '50%', width: 30, border: '1px solid grey', marginRight: 10}}></div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
  ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
  facilisis leo vel. .....
                        </div>
                      </CardContent>
                    </Card>))}
                  {[1, 2, 3, 4].map((v, i) => (
                    <Card key={i} style={{marginBottom: 10, marginTop: 10}}>
                      <CardContent className={classes.cardContent}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
donec ultrices.
                      </CardContent>
                    </Card>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          )}

        </Grid>

      </Grid>
    </Grid>
  );
}; 

export default FluxPage;