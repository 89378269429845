import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { disconnect } from '../../../actions/login_actions';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  titleConnect: {
    color: 'white',
    display: 'flex',
    padding: 5,
    alignItems: 'center',
    margin: 'unset',
    marginBottom: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    marginLeft: 10,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const AuthenticatedTopBar = ({ disconnect }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.background}>
        <Grid
          container
          style={{padding: 10}}
          align={'flex-end'}
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography className={classes.header} style={{ color: 'white' }}>
            Bonjour Anaïs
            {/* {me && me.fullName} */}
          </Typography>
          <div style={{display: 'flex'}}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                style={{color: 'white', height: '100%'}}
                placeholder="Rechercher une publication"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <Button variant="outlined" style={{marginRight: 10}} color="primary"><Typography className={classes.titleConnect} variant="inherit">Mon profil <AccountCircleIcon style={{marginLeft: 5}}/></Typography></Button>
            <Button
              onClick={() => disconnect()}
              className={classes.titleConnect}
              variant="outlined"
              color='primary'
            >
              <Typography variant="inherit">Se déconnecter</Typography>
              <ExitToAppIcon />
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

AuthenticatedTopBar.propTypes = {
  disconnect: PropTypes.func,
  me: PropTypes.object,
};

const mapStateToProps = () => (state) => ({
  me: state.me,
});

export default connect(mapStateToProps, { disconnect })(AuthenticatedTopBar);
