import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import loginReducer from './login_reducer';
import projectsReducer from './projects_reducer';
import userReducer from './user_reducer';
import registerReducer from './register_reducer';

export default combineReducers({
  form: formReducer,
  login: loginReducer,
  projects: projectsReducer, 
  me: userReducer,
  register: registerReducer
});
