import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import RegisterForm from '../../../forms/RegisterForm';
import { userInscription } from '../../../actions/register_actions';
import PropTypes from 'prop-types';
import { useHistory} from 'react-router-dom';

const Register = ({userInscription, register, login}) => {

  const history = useHistory();

  useEffect(() => {  
    if (register.registered) {
      const { from } = { from: { pathname: '/login' } };
      history.replace(from);
    }
    if (login.signedIn) {
      const { from } = { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [register, login, history]);

  const handleSubmit = values => {
    userInscription(values);
  };

  return (
    <Grid item xs={12} sm={12} lg={12} style={{ margin: 'auto' }}>
      <RegisterForm
        onSubmit={handleSubmit}
      />
    </Grid>
  );  };

Register.propTypes = {
  userInscription: PropTypes.func,
  register: PropTypes.object,
  login: PropTypes.object
};

const mapStateToProps = ({ session }) => state => ({
  session,
  register: state.register,
  login: state.login,
});

export default connect(
  mapStateToProps,
  { userInscription })(Register);

