/* eslint-disable */

import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import './index.css';
// import { Doughnut, Pie, Bar } from 'react-chartjs-2';
import ReactCalendar from './ReactCalendar';
import ChartPage from './ChartPage';
import FluxPage from './FluxPage';

const drawerWidth = 240;

let arrayTextButton = ['Ajouter un flux', 'Ajouter un réseau social', 'Actualiser']
let array = ['Mes Posts', 'Mentions', 'Messages'];
let arrayNumberMessage = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const data = {
  labels: [
    'Red',
    'Green',
    'Yellow'
  ],
  datasets: [{
    data: [300, 50, 100],
    backgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56'
    ],
    hoverBackgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56'
    ]
  }]
};

const dataBar = {
  labels: ['1', '2', '3', '4', '5', '6'],
  datasets: [
    {
      label: '# of Red Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: '# of Blue Votes',
      data: [2, 3, 20, 5, 1, 4],
      backgroundColor: 'rgb(54, 162, 235)',
    },
    {
      label: '# of Green Votes',
      data: [3, 10, 13, 15, 22, 30],
      backgroundColor: 'rgb(75, 192, 192)',
    },
  ],
}

const dataPie = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    background: 'rgb(20, 48, 89)',
    color:'white',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  cardHeader: {
    borderBottom: '1px solid rgb(228 222 222)',
    // border: '1px solid grey'
  }, 
  cardContent: {
    padding: 7,
  },
  drawerClose: {
    background: 'rgb(20, 48, 89)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{background: 'unset',borderBottom: '1px solid grey', boxShadow: 'unset', border: 'none'}}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{justifyContent: 'space-between'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h5" color="primary" noWrap>
            Iris Alpha
          </Typography> */}
          <div>
          {arrayTextButton.map((v) => (
            <Button style={{marginRight: 10}} variant="outlined">
            {v}
          </Button>
          ))}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List style={{padding: 'unset', display: 'flex', height: '100%'}}>
          <div style={{background: '#143059', width: '100%'}}>
        {['Flux', 'Stats', 'Agenda'].map((text, index) => (
              <Link style={{textDecoration: 'unset', display: 'flex', flexDirection: 'column', marginTop: 10, flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center', color: 'white'}} to={text === 'Flux' ? '/flux' : text === 'Stats' ? '/stats' : '/calendar'}>
            <Button >
              <InboxIcon style={{color: 'white'}} />
                  <Typography style={{color: 'white'}}>
                    {text}
                  </Typography>
                  </Button>
                </Link>
        ))}     
          </div>
          <div style={{width: '100%', background: '#eeebe8', display: `${!open ? 'none' : 'block'}` }}>
          {['Facebook', 'Twitter', 'Instagram', 'Linkedin', 'Youtube', 'Voir mes publications', 'Statistiques'].map((text, index) => (
            <Button style={{whiteSpace: 'break-spaces', width: '100%', color: 'black', padding: 10, justifyContent: 'center', display: 'flex', alignItems: 'center', width: '100%', border: '1px solid white'}}>
            <Typography variant="p">
            {text}
            </Typography>
            </Button>
        ))}   
          </div>
        {/* {['Facebook', 'Twitter', 'Instagram', 'Linkedin', 'Youtube', 'Voir mes publications', 'Statistiques'].map((text, index) => (
          <div style={{display: 'flex'}}>
          <div style={{padding: '1rem', border: '1px solid white', display: 'flex', justifyContent: 'center'}}>
          <ListItemIcon style={{display: 'flex', justifyContent: 'center', color: 'white'}}>{<InboxIcon />}</ListItemIcon>
          </div>
          <div style={{width: '100%', display: 'flex', alignItems: 'center', marginLeft: 10, border: '1px solid white'}}>
            bjr
          </div>
        </div>
        ))} */}
          <div>
          {/* {['Facebook', 'Twitter', 'Instagram', 'Linkedin', 'Youtube', 'Voir mes publications', 'Statistiques'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon style={{color: 'white'}}>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))} */}
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div style={{justifyContent: 'center', width: '100%'}}>
        <div>
        {window.location.pathname === '/calendar' &&  <ReactCalendar/>}</div>
        {window.location.pathname === '/stats' && <ChartPage/>}
        {/* <div style={{maxWidth: '50%', display: 'flex'}}>
          {[1,2].map(() => 
          (
            <Doughnut style={{width: '100%', height: '100%'}} data={data} />
          ))}
        </div>
        <div style={{maxWidth: '40%', display: 'flex'}}>
          {[1,2].map(() => 
          (
            <Pie style={{width: '100%', height: '100%'}} data={dataPie} />
          ))}
        </div>
        <div style={{maxWidth: '40%', display: 'flex'}}>
          {[1,2].map(() => 
          (
            <Bar style={{width: '100%', height: '100%'}} data={dataBar} />
          ))}
        </div> */}
        </div>
        {window.location.pathname === '/flux' && <FluxPage/>}
        {/* <Grid container item justify="center">
          <Grid container item lg={12}>
          <Grid container item>
            { array.map((v, i) =>
            <Grid style={{height: '80vh'}} item lg={4}>
            <Card style={{border: 'none', borderRadius: 10, marginRight: 10}}>
              <CardHeader avatar={<FlagIcon/>} className={classes.cardHeader} align="center" title={v}>
              </CardHeader>
            <CardContent className={classes.cardContent} style={{background: v === 'Messages' && 'rgb(239 237 235)', height: '80vh', overflow: 'auto'}}>
              {v === 'Messages' && arrayNumberMessage.map((v) => (
              <Card style={{marginBottom: 10}}>
                <CardContent className={classes.cardContent}>
                  <div>
                <div style={{height: 30, display: 'inline-block', borderRadius: '50%', width: 30, border: '1px solid grey', marginRight: 10}}></div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
        facilisis leo vel. .....
        </div>
                </CardContent>
              </Card>))}
              {[1, 2, 3, 4].map((v) => (
              <Card style={{marginBottom: 10, marginTop: 10}}>
              <CardContent className={classes.cardContent}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
      ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
      facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
      gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
      donec ultrices.
              </CardContent>
            </Card>
              ))}
            </CardContent>
          </Card>
            </Grid>
            )}

        </Grid>

          </Grid>
        </Grid> */}
      
      </main>
    </div>
  );
}
