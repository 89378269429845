import {
  REGISTER_TENTATIVE,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from './types';
  
import registerApi from '../api/register_api';

export const userInscription = (values) => dispatch => {
  dispatch({ type: REGISTER_TENTATIVE });

  let informations = {
    username: values.username,
    plainPassword: values.password,
    fullName: values.fullname,
    email: values.email
  };

  registerApi
    .register(informations)
    .then(() => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
    }).catch(() => 
      dispatch({
        type: REGISTER_FAIL,
      })
    )
  ;
};