/* eslint-disable */


import React from 'react';
import { BrowserRouter , Switch, Route } from 'react-router-dom';
import AppRoute from '../components/atoms/AppRoute';
import Login from '../page/auth/login/Login';
import Register from '../page/auth/register/Register';
import Home from '../page/home/Home';
import DashboardProject from '../page/project/Dashboard';
import ErrorPage from '../page/404/404';
// import Footer from '../components/molecules/Footer/Footer';
import Header from '../components/molecules/Header/Header';
import AuthenticatedTopBar from '../components/molecules/AuthenticatedTopBar/AuthenticatedTopBar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function RouterJS() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/register"
          exact
        >
            <div className="subcore">
              <Header/>
              <Register/>
            </div>
            {/* <Footer/> */}
        </Route>
        <AppRoute
          path="/"
          exact
        >
          <div className="subcore">
            <AuthenticatedTopBar/>
            <Header/>
            <Home/>
          </div>
          {/* <Footer/> */}
        </AppRoute>
        <AppRoute
          path="/flux"
          exact
        >
          <DashboardProject/>
        </AppRoute>
        <AppRoute
          path="/stats"
          exact
        >
          <DashboardProject/>
        </AppRoute>
        <AppRoute
          path="/calendar"
          exact
        >
          <DashboardProject/>
        </AppRoute>
        <Route
          path="/login"
          exact
        >
          <div className="subcore">
            <Header/>
            <Login/>
          </div>
          {/* <Footer/> */}
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}

RouterJS.propTypes = {
  signedIn: PropTypes.bool,
};

const mapStateToProps = () => state => ({
  signedIn: state.login.signedIn,
});

export default connect(mapStateToProps, undefined)(RouterJS);
