import React from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

const dummyEvents = [
  {
    allDay: false,
    end: new Date('December 10, 2017 11:13:00'),
    start: new Date('December 09, 2017 11:13:00'),
    title: 'hi',
  },
  {
    allDay: true,
    end: new Date('December 09, 2017 11:13:00'),
    start: new Date('December 09, 2017 11:13:00'),
    title: 'All Day Event',
  },
];

const ReactCalendar = () => {    
  return (
    <Calendar
      localizer={localizer}
      events={dummyEvents}
      startAccessor="start"
      defaultView='week'
      endAccessor="end"
    />        
  );
}; 

export default ReactCalendar;