import {
  LOGIN_TENTATIVE,
  LOGIN_TENTATIVE_SUCCESS,
  LOGIN_TENTATIVE_FAIL,
  LOGOUT_REQUESTED,
} from '../actions/types';
  
const initialState = {
  loaded: false,
  signedIn: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_TENTATIVE: {
      return {
        ...state
      };
    }
    case LOGIN_TENTATIVE_SUCCESS: {
      const token = action.token;

      return {
        token,
        signedIn: true,
        loaded: true,
      };
    }
    case LOGOUT_REQUESTED: {
      return {
        signedIn: false,
        loaded: false,
      };
    }
    case LOGIN_TENTATIVE_FAIL: {
      return {
        signedIn: true,
        loaded: true,
      };
    }
    default:
      return state;
  }
}
