import React from 'react';
import { Doughnut, Pie, Bar } from 'react-chartjs-2';

const data = {
  labels: [
    'Red',
    'Green',
    'Yellow'
  ],
  datasets: [{
    data: [300, 50, 100],
    backgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56'
    ],
    hoverBackgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56'
    ]
  }]
};
  
const dataBar = {
  labels: ['1', '2', '3', '4', '5', '6'],
  datasets: [
    {
      label: '# of Red Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: '# of Blue Votes',
      data: [2, 3, 20, 5, 1, 4],
      backgroundColor: 'rgb(54, 162, 235)',
    },
    {
      label: '# of Green Votes',
      data: [3, 10, 13, 15, 22, 30],
      backgroundColor: 'rgb(75, 192, 192)',
    },
  ],
};
  
const dataPie = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const ChartPage = () => {    
  return (
    <>
      <div style={{maxWidth: '50%', display: 'flex'}}>
        {[1,2].map((v, i) => 
          (
            <Doughnut key={i} style={{width: '100%', height: '100%'}} data={data} />
          ))}
      </div>
      <div style={{maxWidth: '40%', display: 'flex'}}>
        {[1,2].map((v, i) => 
          (
            <Pie key={i} style={{width: '100%', height: '100%'}} data={dataPie} />
          ))}
      </div>
      <div style={{maxWidth: '40%', display: 'flex'}}>
        {[1,2].map((v, i ) => 
          (
            <Bar key={i} style={{width: '100%', height: '100%'}} data={dataBar} />
          ))}
      </div>
    </>
  );
}; 

export default ChartPage;