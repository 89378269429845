import {
  LOGIN_TENTATIVE,
  LOGIN_TENTATIVE_SUCCESS,
  LOGIN_TENTATIVE_FAIL,
  LOGOUT_REQUESTED,
  GET_ME_SUCCESS,
  GET_ME_FAIL
} from './types';
  
import sessionApi from '../api/session_api';
import {addAutorization, removeAuthorization} from '../helpers/Api';
// import {getMe} from './user_actions';
import Api from '../helpers/Api';

export const checkSession = () => dispatch => {
  dispatch({ type: LOGIN_TENTATIVE });
  let token = localStorage.getItem('token');


  if (token) {
    addAutorization(token);
    getMe()
      .then(user => {
        dispatch({
          type: GET_ME_SUCCESS,
          me: user
        });
        dispatch({ type: LOGIN_TENTATIVE_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: GET_ME_FAIL });
        dispatch({type: LOGIN_TENTATIVE_FAIL});
      });
  } else {
    dispatch({ type: GET_ME_FAIL });
    dispatch({type: LOGIN_TENTATIVE_FAIL});
  }
};

export const disconnect = () => dispatch => {
  let token = localStorage.getItem('token');
  if (token)
  {
    localStorage.removeItem('token');
    dispatch({ type: LOGOUT_REQUESTED });
  }
};

export const createSessionToken = (values) => dispatch => {
  dispatch({ type: 'LOGIN_TENTATIVE' });
  
  let informations = {
    username: values.email,
    password: values.password,
  };
  
  sessionApi
    .login(informations)
    .then(res => {
      let data = res.data;
      let { token, refresh_token } = data;
      token && localStorage.setItem('token', token);

      let tokenStorage = localStorage.getItem('token');
      tokenStorage && addAutorization(tokenStorage);
      
      getMe()
        .then(user => {
          dispatch({
            type: GET_ME_SUCCESS,
            me: user
          });
        })
        .catch(() => {
          dispatch({ type: GET_ME_FAIL });
          dispatch({type: LOGIN_TENTATIVE_FAIL});
        });


      dispatch({
        type: LOGIN_TENTATIVE_SUCCESS,
        // payload: data,
        token: token,
        refresh_token: refresh_token
      });
      // in Ruby jwt with DEVISE
    })
    .catch(error => dispatch({ type: LOGIN_TENTATIVE_FAIL, error }));
};
  

export const getMe = () => {
  return new Promise((resolve, reject) => {
    Api('user/me')
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) 
        {
          removeAuthorization();
          localStorage.removeItem('token');
        }
        reject(err);
      });
  });};